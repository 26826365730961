.navbar-brand img{
    display: none;
}

.navbar-inverse .nav.navbar-nav.hidden-xs {
    display: none;
}

.navbar-inverse .nav.navbar-nav.navbar-right.hidden-xs {
    display: block;
}